@font-face {
  font-family: 'Tangerine';
  src: url('../resources/fonts/Tangerine-Regular.ttf') format('truetype');
}

/* body {
  overflow-y: visible;
} */

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* body, #root, .App {
  width: 100%;
  height: 100%;
} */

/* .nav-button {
  font-size: 1.2rem;
  text-align: center;
} */

#header-bar {
  background-color: chartreuse;
}

.opaque-green {
  background-color: rgba(100, 255, 0, 0.7);
}

#nav-buttons {
  height: 100%;
}

#react-icon {
  width: 64px;
  height: 45px;
}

/* #nav-button {
  margin: 20px;
} */

#profile-picture {
  width: 25%;
  height: 25%;

  min-width: 170px;
  min-height: 170px;
}

/* body {
  background-image: url("./resources/images/bg.jpg");
  background-size: cover;
  background-color: rgba(0, 0, 0, 1);

} */

.blog-link, .blog-link:hover, .blog-link:link {
  text-decoration: none;
}

#posts-heading {
  font-size: 50px;
  /* font-weight: bold; */
}

/* #sidebar-main {
  margin-left: 200px;
} */

#ext-link-icon {
  width: 0.8rem;
  height: 0.8rem;
}

#author-name {
  font-weight: bold;
}

/* #profile-picture {
  z-index: 9999 !important;
} */

.portfolio-image {
  width: 100%;
  height: 100%;
}

.portfolio-heading {
  font-size: 4rem;
  margin-top: 30px;
  margin-bottom: 20px;
  /* text-decoration: underline; */
  font-family: 'Tangerine', cursive;
}

#social-links {
  position: absolute;
  bottom: 5px;
}

.social-link {
  width: 30%;
  height: 30%;
}

.alabs-image {
  background-color: #3b3936;
}

.w3-myfont {
  font-family: 'Tangerine', cursive;
  font-size: 60px;
}

#hamburger-menu {
  width: 32px;
  height: 32px;
}

.span-steam {
  font-weight: bold;
}

#hamburger-menu-button {
  top: 0;
  left: 0;
  position: absolute;
}

.portfolio-header {
  font-size: 18px;
}

.portfolio-item {
  width: 100%;
}